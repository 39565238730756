import { graphql } from "gatsby"
import React from "react"
import { FaStar } from "react-icons/fa"
import tw from "tailwind.macro"
import Button from "../../components/button"
import CollapsibleBox from "../../components/collapsibleBox"
import Grid from "../../components/grid"
import Layout from "../../components/layout"
import LeadText from "../../components/leadText"
import Masthead from "../../components/masthead"
import Panel from "../../components/panel"
import Section from "../../components/section"
import SEO from "../../components/seo"

const ProjecstDataAnalytics = ({ data, location }) => {
  const parentTitle = "Projects"
  const pageTitle = "Data Analytics"

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} image={data.hero.childImageSharp.fluid.src} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.hero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:40vh;`]}
      />
      <Section css={tw`py-10`}>
        <div className="container">
          <h2>Overview</h2>
          <LeadText>
            We are strengthening state capacity to leverage data analytics to
            pass laws and policies that reduce poverty and inequality and to
            embrace a culture of data based decision-making. The focus is
            assessing and predicting the likely social justice impact of planned
            policies, legislation and plans as well as those already in
            operation.
          </LeadText>
          <p>
            To this end, we are developing Social Justice Impact Assessment
            Tools (SJIAT). These tools principally focus on poverty and social
            inclusion, relating to the following grounds:
          </p>
          <Grid>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} /> Race
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} /> Gender
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} /> Disability
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} />{" "}
              Socio-economic Status
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} /> Sexual
              Orientation and Gender Fluidity
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} />{" "}
              Rural/Urban divide
            </Panel>
            <Panel css={tw`font-bold`}>
              <FaStar css={tw`text-maroon text-xl align-text-top`} />{" "}
              Nationality
            </Panel>
          </Grid>
        </div>
      </Section>
      <Section isShaded>
        <CollapsibleBox collapsibleByDefault>
          <h2>The process</h2>
          <p>
            In using this tool, policy makers will be assisted to implement
            planned policies in the virtual space without adversely impacting
            society. Where the social impact assessment shows a likely disparate
            impact on certain group, which is unintended and inimical to the
            achievement of equality as envisaged in the constitution, it can
            then be decided whether the policy should be abandoned, adjusted or
            to introduce a compensation strategy to mitigate the adverse impact.
          </p>
        </CollapsibleBox>
        <CollapsibleBox collapsibleByDefault>
          <h2>Action items</h2>
          <div>
            <ul>
              <li>
                <strong>
                  Research that summarises the existence of similar analytical
                  tools globally, identifying gaps and outlining the key design
                  considerations for the social justice analytical tools to be
                  designed under the project.
                </strong>
              </li>
              <li>
                <strong>The comparison should look at things like:</strong>
                <ol>
                  <li>
                    What is the instrument for? For example, both SEIAS and our
                    Social Justice Impact Assessment Framework are meant to
                    predict the likely social impact of a policy before it’s
                    implemented. This will serve to warn policy-makers of likely
                    unintended deleterious consequences on certain groups in
                    society.
                  </li>
                  <li>
                    What is the main content? Does the instrument predict the
                    likely social justice impact or is it doing more or less?
                  </li>
                  <li>
                    What is the scope of application? The instrument may be for
                    an inimical region, for example the OECD, a country or for
                    an institution. The substance scope may be limited to one
                    sector for example, health, as in the case of the Yale
                    approach or may have a transversal reach, such as is the
                    case with SEIAS.
                  </li>
                  <li>
                    Who is it intended for? Is it every policy and
                    legislation-maker or plan-designer as ours is intended for
                    or is it for a separate impact assessment unit that assessed
                    afterwards?
                  </li>
                  <li>
                    At what point is the instrument employed? Is it employed
                    before the legislation or policy goes to sign-off
                    authorities such as Parliament, or before a policy or law or
                    plan is approved?
                  </li>
                  <li>
                    Is the analysis quantitative or qualitative: Does the
                    instrument primarily use data analytics to predict the
                    likely impact of a policy, law or plan as ours is intended
                    to. If so, how is public participation or potential
                    user/public input sourced and integrated.
                  </li>
                  <li>
                    How is the Analysis done? Is it automated as we intend ours
                    ( incorporates algorithms) or is the work gone manually or
                    partly automated and manual?
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  Data compilation with an emphasis on data disaggregated in
                  terms of race, gender, disability, spatial disparities and
                  other social justice concerns.
                </strong>{" "}
                Instruction: Prepare a matrix on the following categories of
                social justice: (i) Race, (ii) Gender, (iii) Disability, (iv)
                LGBTQI, (v) Class, (vi) Rural versus Urban, (vii) Nationality.
                The purpose of this matrix is to:
                <ol>
                  <li>
                    determine the disparate impact/unintended consequences of a
                    policy;
                  </li>
                  <li>
                    test who has been excluded unintentionally from the
                    allocation of accommodation on campus by adding values that
                    are irrational as far as accommodation is concerned and that
                    does not add to the equality and human dignity of our
                    students;
                  </li>
                  <li>show the statistical impact of the unrevised policy;</li>
                  <li>
                    help policy-designers to measure new unimplemented policies
                    against this tool before finalization;
                  </li>
                  <li>
                    determine the impact in the virtual world before it is too
                    late
                  </li>
                </ol>
                <strong>Theory of change:</strong>
                <br />
                <ol>
                  <li>
                    Based on these findings, the new policy can be assessed
                    against the same test in order to discern if and to what
                    extent there is a disparate social impact.
                  </li>
                  <li>
                    Our theory of change does not presume to exclude other
                    solutions.
                  </li>
                  <li>
                    It aims to add data analytics to existing tools so that
                    qualitative data still needs to be accessed.
                  </li>
                  <li>
                    The consequences within 1/2/5/10 years can thus be accessed.
                  </li>
                  <li>
                    When this has been achieved, it can be decided:
                    <ol>
                      <li>
                        Whether the policy needs to be give a complete
                        overhaul/redrafted;
                      </li>
                      <li>Adjusted; or</li>
                      <li>
                        To make amends to those that are excluded
                        unintentionally.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  Coordinate the social impact assessment of selected pilot
                  policies
                </strong>
                , currently identified as the Promotion of Equality and
                Prevention of Unfair Discrimination Act, Broad Based Black
                Economic Empowerment Act, South African Schools Act, Health and
                Nutrition Policy frameworks and Land and Property policies
                (establish who has been doing impact assesment of any of these
                Acts/policies).
              </li>
              <li>
                <strong>
                  Design a poverty and inequality map and encourage social
                  justice championship that has targeted the poorest areas on
                  it.
                </strong>
              </li>
            </ul>
          </div>
        </CollapsibleBox>
      </Section>
      <Section>
        <h2>Get started</h2>
        <p>Contact us for further information.</p>
        <Button to="/contact/">Contact us &rarr;</Button>
      </Section>
    </Layout>
  )
}

export default ProjecstDataAnalytics

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "projects-data-analytics-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
